import React, { useEffect } from "react";
import { PageSection } from "../../styles/index/_components";
import Layout, { PageWrapper } from "../../components/layout";
import Human from "../../components/people/Human";
import { StaticImage } from "gatsby-plugin-image";
import "twin.macro";
import { cleanUpZnanyLekarz, loadZnanyLekarz } from "../../utils/utils";
import Enumeration from "../../components/text/enumeration";

const LukaszText = {
    name: "Łukasz",
    surname: "Warmowski",
    phone: ["697", "265", "561"],
    desc1:
        "Jestem dyplomowanym psychologiem. Ukończyłem jednolite studia magisterskie na Uniwersytecie Gdańskim, gdzie oprócz szerokiego zakresu wiedzy na temat trudności, z jakimi borykają się ludzie, miałem też okazję wykorzystać zdobytą wiedzę w kontakcie z pacjentem. Przeprowadziłem liczne diagnozy oraz odbyłem praktyki w Szpitalu Specjalistycznym w Kościerzynie.",
    desc2:
        "Ukończyłem liczne kursy i szkolenia, które przygotowały mnie do pracy z klientami doświadczającymi wielu, szeroko pojętych trudności życiowych. Dotyczyły obszernego spektrum problemów, z jakimi mogą borykać się ludzie, między innymi: przechodzenie żałoby, borykanie się z natręctwami czy długotrwałym, uporczywym cierpieniem zarówno osobistym jak i w relacjach z innymi. Nieustannie edukuję się i zwiększam zakres swoich kompetencji, by w jak największym stopniu móc wspierać klientów.",
    desc3:
        "Szczególnie interesuję się wykorzystaniem terapii schematów w leczeniu różnorodnych zaburzeń, a zdobytą w ten sposób wiedzę wykorzystuję w trakcie pracy. W przyszłości zamierzam ukończyć kurs psychoterapii i uzyskać certyfikację w nurcie terapii schematów.",
    desc4:
        "Najbardziej istotnym czynnikiem dla mnie jest relacja terapeutyczna, która tworzy się między mną a klientem. Zapewniam bezpieczną przestrzeń do bycia sobą, bez konieczności zakładania maski. Cenię szczerość, autentyczność i zaufanie, które staramy się wspólnie zbudować. Staram się zapewnić każdemu bezpieczną i spokojną atmosferę.",
    desc5:
        "Pracuję szczególnie z osobami doświadczającymi zaburzeń lękowych, afektywnych (w tym depresji), borykających się z trudnościami w relacjach interpersonalnych oraz używających substancji psychoaktywnych.",
    desc6:
        "Jak można zauważyć nosze ciemne okulary, jest to spowodowane moją niepełnosprawnością w zakresie narządu wzroku. W razie jakichkolwiek wątpliwości czy ewentualnych trudności tym spowodowanych, proszę o rozmowe.",
};

const Lukasz = () => {
    /*useEffect(() => {
        cleanUpZnanyLekarz(document, "zl-widget-s");
        loadZnanyLekarz(document, "script", "zl-widget-s");
    });
*/
    return (
        <Layout>
            <PageSection>
                <PageWrapper>
                    <div tw="grid grid-flow-row md:grid-flow-col md:grid-cols-2 items-start">
                        <StaticImage
                            alt={"human picture"}
                            src={"../../images/people/lukasz.JPG"}
                            placeholder="blurred"
                            layout="fullWidth"
                        />
                        <Human human={LukaszText} />
                    </div>
                    {/*<div>
                        <Enumeration
                            title={"Pomoc psychologiczna w zakresie:"}
                            items={[
                                "Ustalanie celów życiowych, granic i potrzeb",
                                "Sytuacje kryzysowe (utrata pracy, choroby, śmierć bliskiej osoby)",
                                "Podnoszenie samooceny",
                                "Zrozumienie i kontrolowanie emocji",
                            ]}
                        />
                        <Enumeration
                            title={"Pomoc seksuologiczna w zakresie:"}
                            items={[
                                "Edukacja psychoseksualna dzieci i młodzieży oraz osób dorosłych",
                                "Pomoc w określeniu normy rozwojowej dzieci i młodzieży",
                                "Wsparcie osób o różnych orientacjach i tożsamościach płciowych oraz ich rodzin",
                                "Wsparcie pacjenta oraz jego rodziny w procesie tranzycji",
                                "Praca z osobami po wykorzystaniu seksualnym",
                            ]}
                        />
                    </div>
                    <div>
                        <a
                            id="zl-url"
                            className="zl-url"
                            href="https://www.znanylekarz.pl/justyna-barton/psycholog-seksuolog/gdansk"
                            rel="nofollow"
                            data-zlw-doctor="justyna-barton"
                            data-zlw-type="big_with_calendar"
                            data-zlw-opinion="false"
                            data-zlw-hide-branding="true"
                        >
                            Justyna Barton - ZnanyLekarz.pl
                        </a>
                    </div>*/}
                </PageWrapper>
            </PageSection>
        </Layout>
    );
};

export default Lukasz;
