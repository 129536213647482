import React from "react";
import "twin.macro";

const Enumeration = ({ title, items = [] }) => {
  return (
    <div tw="flex p-6">
      <div>
        <div tw="text-xl font-bold">{title}</div>
        <ul tw="list-inside list-disc">
          {items.map((item) => {
            return <li>{item}</li>;
          })}
        </ul>
      </div>
    </div>
  );
};

export default Enumeration;
